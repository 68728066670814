import { API_CALL_STATUS } from "@/constant/config";

export default {

    props: {
        defaultData: {
            type: Array,
            default: []
        },
        listData: {
            type: Array,
            default: []
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        msgPage: {
            type: String,
            default: 'Unknown'
        },
    },

    data() {
        return {
            filterValue: null,
        };
    },

    computed: {
    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Refresh
        refresh() {
            this.$emit("processingEvent", { event: "performAction", data: { action: 'refresh' } });
        },
        statusColor(element) {
            return element.machineStatus === 3
                && !element.bootReady ? 'darkorange' : this.$machineStatus.find((x) => x.status === element.machineStatus).color;
        },
        // Filter list
        filterList() {
            this.listData = this.defaultData.filter(x => x.machineId.toString().includes(this.filterValue));
            this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
        },

        // Add all
        addAll() {
            this.listData.forEach(element => {
                element.isChecked = true;
            });
        },

        // Remove all
        removeAll() {
            this.listData.forEach(element => {
                element.isChecked = false;
            });
        },
    }
} 